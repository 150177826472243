import { t } from "../../translate";
import { useNavigate } from "react-router-dom";
import SignupLayout from "../../components/layouts/signup-layout";
import CardButton from "../../components/atomics/card-button";
import { ReactComponent as UserColorful } from "../../components/assets/ic_user_colorful.svg";
import { ReactComponent as CorpColorful } from "../../components/assets/ic_corp_colorful.svg";
import styles from "../../components/styles/signup-index.module.scss";
import { Col, Row } from "antd";

const Signup = () => {
    const navigate = useNavigate();
    return (
        <SignupLayout description={t("signup.index.description")}>
            <Row className={styles.signupOptionsContainer} gutter={[20, 20]}>
                <Col xs={24} lg={12} className={styles.cardContainer}>
                    <CardButton
                        icon={<UserColorful height={"7.5rem"} width={"auto"} />}
                        label={t("signup.index.firstCardTitle")}
                        onClick={(e: any) => {
                            navigate("/signup/form?role=customer");
                        }}
                    />
                </Col>
                <Col xs={24} lg={12} className={styles.cardContainer}>
                    <CardButton
                        icon={<CorpColorful height={"7.5rem"} width={"auto"} />}
                        label={t("signup.index.secondCardTitle")}
                        onClick={(e: any) => {
                            navigate("/signup/form?role=lessor");
                        }}
                    />
                </Col>
            </Row>
        </SignupLayout>
    );
};

export default Signup;

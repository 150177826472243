import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./extensions/impls/string.extensions";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./helpers/scroll-to-top";
import { ConfigProvider } from "antd";

if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
}
console.log("\n\n:::MACHINEGO:::\n\n");

ReactDOM.render(
    <BrowserRouter>
        <ScrollToTop />
        <ConfigProvider virtual={false}>
            <App />
        </ConfigProvider>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Form, Radio, RadioChangeEvent, Row, Space } from "antd";
import LinkButton from "../../components/atomics/link-button";
import PasswordInput from "../../components/atomics/password-input";
import PrimaryButton from "../../components/atomics/primary-button";
import TextButton from "../../components/atomics/text-button";
import UsernameInput from "../../components/atomics/username-input";
import AuthLayout from "../../components/layouts/auth-layout";
import { t } from "../../translate";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAtom } from "jotai";
import { RoleType, loadingAtom } from "../../store/global-atoms";
import { useSecureService } from "../../store/service-atoms";
import { useEffect, useState } from "react";
import RadioRoleButton from "../../components/atomics/radio-role-button";
import { logoutChannel } from "../../App";

const Login = () => {
    const { doLogin, doLogout } = useSecureService();
    const navigate = useNavigate();
    const [query, setQuery] = useSearchParams();
    const queryRole = (query.get("role") as RoleType) ?? "lessor";
    const queryLogout = query.get("isLogout");
    const [nonsecureRole, setNonsecureRole] = useState<RoleType | undefined>(undefined);
    const phone = query.get("phone");
    const reservationId = query.get("reservationid");
    const machineId = query.get("machineId");
    const fromRequest = query.get("request") ? "&request=true" : "";
    const [loading] = useAtom(loadingAtom);

    useEffect(() => {
        console.log(queryLogout, " ", queryRole);
        if (queryLogout && queryRole) {
            if (queryRole === "lessor") {
                logoutChannel.postMessage("MG_LOGGED_OUT_NOTIFICATION_LESSOR");
            } else {
                logoutChannel.postMessage("MG_LOGGED_OUT_NOTIFICATION_RENTER");
            }

            const logoutCall = async () => {
                await doLogout();
                navigate("/login?role=" + queryRole);
            };
            logoutCall();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryLogout, queryRole]);

    useEffect(() => {
        if (queryRole === "customer") {
            setNonsecureRole("customer");
        } else if (queryRole === "lessor") {
            setNonsecureRole("lessor");
        }
        //eslint-disable-next-line
    }, [queryRole]);

    const formFinishHandler = async (parameters: { phone: string; password: string }) => {
        var editedPhone = parameters.phone.replace("+90", "");
        editedPhone = editedPhone.replace(/\s+/g, "");
        const result = await doLogin(queryRole, { password: parameters.password, username: editedPhone });
        if (result) {
            if (machineId && reservationId)
            {
                navigate("/reservation/detail?macid=" + machineId + "&reservationId=" + reservationId);
            }
            else {
                 navigate("/dashboard?role=" + queryRole + fromRequest);
            }
           
        }
    };

    const onChange = (e: RadioChangeEvent) => {
        if (reservationId) {
          setNonsecureRole(e.target.value);
          query.set("role", e.target.value);
          setQuery(query);  
        } 
    };

    const onRadioDivClicked = (param: string) => {
        if (!reservationId) {
            setNonsecureRole(param === "lessor" ? "lessor" : "customer");
            query.set("role", param);
            setQuery(query);
        }
    };

    return (
        <AuthLayout>
            <Form
                name="normal_login"
                className="login-form"
                layout="vertical"
                onFinish={formFinishHandler}
                initialValues={{ phone: phone ?? "+90" }}
            >
                {!reservationId && <span>{t("login.cardTitle")}</span>}
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Radio.Group onChange={onChange} value={nonsecureRole}>
                        <Row style={{ justifyContent: "center", alignItems: "center" }}>
                            {!reservationId && (
                                <RadioRoleButton
                                    radioClicked={onRadioDivClicked}
                                    value="lessor"
                                    content={t("common.makinesahibi")}
                                    isChecked={nonsecureRole === "lessor"}
                                />
                            )}
                            <RadioRoleButton
                                radioClicked={onRadioDivClicked}
                                value="customer"
                                content={t("common.customer")}
                                isChecked={nonsecureRole === "customer"}
                            />
                        </Row>
                    </Radio.Group>
                </div>
                {nonsecureRole === undefined ? (
                    <></>
                ) : (
                    <>
                        <div>
                            <UsernameInput prefix initialValue={phone ?? "+90"} />
                        </div>
                        <div>
                            <PasswordInput
                                placeholder={t("login.password.placeholder")}
                                validationMessage={t("login.password.validationMessage")}
                                label={t("login.password.label")}
                            />
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <TextButton loading={loading} onClick={() => navigate("/forgot-password?role=" + nonsecureRole)} type="link">
                                {t("login.forgotPassword")}
                            </TextButton>
                        </div>
                        <div>
                            <PrimaryButton loading={loading} htmlType="submit">
                                {nonsecureRole === "lessor" ? t("login.continue.lessor") : t("login.continue.renter")}
                            </PrimaryButton>
                        </div>
                    </>
                )}
                {!reservationId && (
                    <Row justify="center">
                        <div style={{ textAlign: "center", fontSize: '14px' }}>
                            <Space>
                                {t("login.signup.init")}
                                <LinkButton style={{fontSize: '14px'}} loading={loading} onClick={() => navigate("/signup")}>
                                    {t("login.signup.link")}
                                </LinkButton>
                                <ArrowRightOutlined style={{ color: "#FEB111" }} />
                            </Space>
                        </div>
                        <div style={{ fontSize: '0.8rem', textAlign: "center" }}>
                            <span>
                                {t("login.agreement.part-1") + ' ' } 
                                <span onClick={() => navigate("/aydinlatma-metni")} style={{fontWeight: 800, textDecoration: 'underline', cursor: 'pointer'}}>{t("login.agreement.part-2") + ' '}</span>
                                {t("login.agreement.part-3") + ' '} 
                                <span onClick={() => navigate("/kullanici-sozlesmesi")} style={{fontWeight: 800, textDecoration: 'underline', cursor: 'pointer'}}>{t("login.agreement.part-4")}</span>
                                {t("login.agreement.part-5") + ' '} 
                                <span onClick={() => navigate("/kurumsal-ve-bireysel-uye-acik-riza-metni")} style={{fontWeight: 800, textDecoration: 'underline', cursor: 'pointer'}}>{t("login.agreement.part-6")}</span>
                                {t("login.agreement.part-7")}
                            </span>
                        </div>
                    </Row>
                )}
            </Form>
        </AuthLayout>
    );
};

export default Login;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import style from "./machine-detail.module.scss";
import { Col, Divider, Modal, Row, Spin } from "antd";
import { ReactComponent as Info } from "../../../components/assets/info.svg";
import { useState } from "react";
import Carousel from "react-multi-carousel";
import SecureLayout from "../../../components/layouts/secure-layout";
import { useSecureService } from "../../../store/service-atoms";
import PrimaryButton from "../../../components/atomics/primary-button";
import { t } from "../../../translate";
import { LessorCompanyAuthorizedSellerInfoModel, LessorPurpose, MachineDetailModel, SaleMachineStatisticViewModel} from "../../../service";
import InfoBox from "../../../components/atomics/info-box";
import { activeApiBaseUrl } from "../../../store/app-constants";
import CustomArrows from "../../../components/atomics/custom-arrows";
import { useQuery } from "../../../helpers/use-query";
import BoxContainer from "../../../components/containers/box-container";
import placeholder from "../../../components/assets/placeholder.svg";
import { formatCurrency } from "../../../helpers/formatters";
import { useNavigate } from "react-router-dom";
import { moment } from "../../../helpers/moment";
import { extendedMachineDetailAtom, lessorPurposeAtom, loadingAtom, roleAtom } from "../../../store/global-atoms";
import { useAtom } from "jotai";
import MachineShowcaseRemovalModal from "../../../modals/machine-showcase-removal-modal";
import { ExtendedMachineDetailModel } from "../../../service/models/ExtendedMachineDetailModel";
import { ReactComponent as GreenCircle } from "./../../../../src/components/assets/greenCircle.svg";
import { ReactComponent as YellowCircle } from "./../../../../src/components/assets/yellowCircle.svg";
import { ReactComponent as RedCircle } from "./../../../../src/components/assets/redCircle.svg";
import { isNumber } from "lodash";
import MachineSaleStatisticPart from "../../../page-parts/machine-sale-statistic-part";

const SaleMachineDetail = () => {
    const [machineAllDetail, setMachineAllDetail] = useState<MachineDetailModel | null>(null);
    const query = useQuery();
    const macid = query.get("macid");
    const { doGetSaleMachine, doRemoveSaleMachine, doGetMachineSellerContact, doSaleDetailViewNotification, doSaleLessorViewNotification, doGetSaleMachineStatic} = useSecureService();
    const [loading, setLoading] = useState(true);
    const [showRemoveFromShowcaseModal, setShowRemoveFromShowcaseModal] = useState(false);
    const [showRemoveShowcaseAccepted, setShowRemoveShowcaseAccepted] = useState(false);
    const [showRemoveShowcaseError, setShowRemoveShowcaseError] = useState(false);
    const [atomLoading] = useAtom(loadingAtom);
    const navigate = useNavigate();
    const [showLessorCompanyModal, setShowLessorCompanyModal] = useState<boolean>(false);
    const [role] = useAtom(roleAtom);
    const [lessorInfo, setLessorInfo] = useState<LessorCompanyAuthorizedSellerInfoModel>();
    const [, editMachine] = useAtom(extendedMachineDetailAtom);
    const [, setLessorPurpose] = useAtom(lessorPurposeAtom);
    const [statistic, setStatistic] = useState<SaleMachineStatisticViewModel | null>(null);

    useEffect(() => {
        const getMachineDetails = async () => {
            if (macid) {
                const macidN = parseInt(macid);
                let result: undefined | ExtendedMachineDetailModel = undefined;
                if (!Number.isNaN(macidN)) {
                    result = await doGetSaleMachine(macidN);
                    if (result) {
                        setMachineAllDetail(result);
                        editMachine(result);
                    }
                    console.log(JSON.stringify(result));
                }
                if (result === undefined) {
                    setLoading(false);
                }
            }
        };
        const sendSaleDetailViewNotification = async () => {
            const id = Number.parseInt(macid ?? "")
            if (isNumber(id)) {
                await doSaleDetailViewNotification(id);
            }
        }

        const getSaleMachineStatistic = async () => {
            const id = Number.parseInt(macid ?? "")
            if (isNumber(id)) {
                const statistic = await doGetSaleMachineStatic(id);
                setStatistic(statistic ?? null);
            }
        }
        if (role === "customer") sendSaleDetailViewNotification();
        else getSaleMachineStatistic();
        getMachineDetails();
    }, [macid]);
    const [mainImageId, setMainImageId] = useState<number>();
    const [mainImageUrl, setMainImageUrl] = useState<string>(placeholder);
    const machineImages = machineAllDetail?.documents?.filter((x) => x.documentCategory === "Photo");
    useEffect(() => {
        if (role === "lessor") {
            setMainImageUrl(activeApiBaseUrl + "/document/" + mainImageId);
        } else {
            setMainImageUrl(activeApiBaseUrl + "/document/machine-document/" + mainImageId);
        }
    }, [mainImageId]);

    useEffect(() => {
        if (machineAllDetail?.documents && machineAllDetail.documents.length > 0) {
            const mainImageId = machineAllDetail?.documents?.filter((x) => x.documentCategory === "Photo").at(0)?.document?.id;
            setMainImageId(mainImageId);
        }
        if (machineAllDetail) {
            setLoading(false);
        }
    }, [machineAllDetail]);
    useEffect(() => {
        if (mainImageId === undefined) {
            setMainImageUrl(placeholder);
        }
    }, [mainImageId]);

    const sendSaleLessorViewNotification = async () => {
        const id = Number.parseInt(macid ?? "")
        if (isNumber(id)) {
            await doSaleLessorViewNotification(id);
        }
    }

    const doGetLessorInfo = async () => {
        const result = await doGetMachineSellerContact(machineAllDetail?.id ?? 0);
        if (result) {
            setLessorInfo(result);
            setShowLessorCompanyModal(true);
            if (role === "customer") sendSaleLessorViewNotification();
        }
    };

    const handleRemoveFromShowcase = async (): Promise<boolean> => {
        if (macid) {
            const res = await doRemoveSaleMachine(Number.parseInt(macid));
            if (res !== undefined) {
                console.log("removed id:" + macid);
                return true;
            } else {
                return false;
            }
        }
        return false;
    };
    return (
        <SecureLayout activePage={"rental-machine-detail"} role={"lessor"}>
            <Row hidden={!loading} style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <Col sm={24}>
                    <Spin size="large" tip="Lütfen Bekleyiniz" className={style.spinMobile} />
                </Col>
            </Row>
            <Row hidden={loading || !(machineAllDetail === null)} style={{ display: "flex", height: "100%" }}>
                <Col sm={24} style={{ display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
                    <span style={{ verticalAlign: "center", fontWeight: "bold" }}>{t("machine-detail.machine-not-found")}</span>
                </Col>
            </Row>

            <Row hidden={loading || machineAllDetail === null} gutter={[24, 24]} className={style.machineDetail} justify="center">
                <Row className={style.machineHeader} justify={"space-between"}>
                    <Col> {t("machine-detail.info")}</Col>
                    <Col className={style.kdv}>
                        <Col style={{ padding: 2 }}>
                            <Info />
                        </Col>
                        <Col style={{ padding: 2 }}>{t("reservation.tax-info")}</Col>
                    </Col>
                </Row>
                {
                    role === "lessor" && (
                        <Row className={style.machineStatusOuterContainer}>
                        <Col xs={24} md={4}>
                            {
                            machineAllDetail?.isMachineRemovedFromSale ? 
                                <div className={style.machineStatusContainer}>
                                    <div style={{ display: "flex", alignContent: "center" }}>
                                        <RedCircle fill="#FC5A5A" style={{ transform: "scale(0.7)" }}></RedCircle>
                                    </div>
                                    <span style={{ color: "#FC5A5A", fontWeight: "800", marginLeft: "0.25rem" }}>
                                        {t("common.machine.status3")}
                                    </span>
                                </div>
                                :
                                machineAllDetail?.isApprovedForSale === true ? 
                                <div className={style.machineStatusContainer}>
                                    <div style={{ display: "flex", alignContent: "center" }}>
                                        <GreenCircle style={{ transform: "scale(0.7)" }}></GreenCircle>
                                    </div>
                                    <span style={{ color: "#35B934", fontWeight: "800", marginLeft: "0.25rem" }}>
                                        {t("common.machine.status1")}
                                    </span>
                                </div>
                                :
                                <div className={style.machineStatusContainer}>
                                    <div style={{ display: "flex", alignContent: "center" }}>
                                        <YellowCircle style={{ transform: "scale(0.7)" }}></YellowCircle>
                                    </div>
                                    <span style={{ color: "#FEB111", fontWeight: "800", marginLeft: "0.25rem" }}>
                                        {t("common.machine.status2")}
                                    </span>
                                </div>
                            }
                        </Col>
                    </Row>
                    )
                }
                <Col style={{ marginBottom: "1rem" }}>
                    <Row gutter={[24, 24]}>
                        <Col md={16}>
                            <Col
                                style={{
                                    height: "50vh",
                                    textAlign: "center",
                                    backgroundColor: "#fff",
                                    width: "100%",
                                    borderRadius: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                md={24}
                            >
                                {machineImages && machineImages?.length && machineImages?.length > 0 ? (
                                    <img
                                        style={{
                                            objectFit: "contain",
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            width: "auto",
                                            height: "auto",
                                        }}
                                        src={mainImageUrl}
                                        alt={""}
                                    ></img>
                                ) : (
                                    <img className={style.placeholderImage} src={placeholder} alt={""}></img>
                                )}
                            </Col>
                            <Col md={24} style={{ paddingTop: "0.75rem" }}>
                                <Carousel
                                    additionalTransfrom={0}
                                    arrows
                                    showDots={false}
                                    autoPlaySpeed={3000}
                                    centerMode={false}
                                    className=""
                                    containerClass={style.containerWithDts}
                                    dotListClass={style.showcaseDots}
                                    draggable
                                    focusOnSelect={false}
                                    infinite={false}
                                    itemClass=""
                                    keyBoardControl
                                    minimumTouchDrag={80}
                                    pauseOnHover
                                    customLeftArrow={<CustomArrows direction="left" />}
                                    customRightArrow={<CustomArrows direction="right" />}
                                    renderArrowsWhenDisabled={false}
                                    renderButtonGroupOutside={false}
                                    renderDotsOutside
                                    responsive={{
                                        desktop: {
                                            breakpoint: {
                                                max: 3000,
                                                min: 1024,
                                            },
                                            items: 6,
                                            partialVisibilityGutter: 40,
                                        },
                                        mobile: {
                                            breakpoint: {
                                                max: 464,
                                                min: 0,
                                            },
                                            items: 6,
                                            partialVisibilityGutter: 30,
                                        },
                                        tablet: {
                                            breakpoint: {
                                                max: 1024,
                                                min: 464,
                                            },
                                            items: 2,
                                            partialVisibilityGutter: 30,
                                        },
                                    }}
                                    rewind={false}
                                    rewindWithAnimation={false}
                                    rtl={false}
                                    shouldResetAutoplay
                                    sliderClass=""
                                    slidesToSlide={1}
                                    afterChange={undefined}
                                    beforeChange={undefined}
                                    swipeable
                                >
                                    <Row gutter={4}>
                                        {machineImages &&
                                            machineImages?.map((x) => (
                                                <Col
                                                    className={style.imgContainer}
                                                    md={3}
                                                    key={x.document?.id}
                                                    onClick={() => {
                                                        if (role === "lessor") {
                                                            setMainImageUrl(activeApiBaseUrl + "/document/" + x.document?.id);
                                                        } else {
                                                            setMainImageUrl(
                                                                activeApiBaseUrl + "/document/machine-document/" + x.document?.id
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <img
                                                        className={style.carouselImg}
                                                        src={
                                                            role === "lessor"
                                                                ? activeApiBaseUrl + "/document/" + x.document?.id
                                                                : activeApiBaseUrl + "/document/machine-document/" + x.document?.id
                                                        }
                                                        alt={"machine-document"}
                                                    />
                                                </Col>
                                            ))}
                                    </Row>
                                </Carousel>
                            </Col>
                        </Col>
                        <Col md={8}>
                            <BoxContainer bodyStyle={{ height: "100%" }}>
                                <Row
                                    align={"stretch"}
                                    justify="space-between"
                                    style={{ height: "100%" }}
                                    gutter={[8, 8]}
                                    className={style.machineDetail}
                                >
                                    <Col>
                                        <Row className={style.machineDetail}>
                                            <Col className={style.detailHead}>
                                                {machineAllDetail?.subCategory?.category?.name +
                                                    " > " +
                                                    machineAllDetail?.subCategory?.name}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className={style.cardInfo}>{machineAllDetail?.machineLocationCity?.name}</Col>
                                    <div className={style.hr}></div>
                                    <Col>
                                        <Row>
                                            <Col md={12} xs={12}>
                                                <h2 className={style.price}>
                                                    {formatCurrency(
                                                        machineAllDetail?.machineSalePrice ?? 0,
                                                        machineAllDetail?.machineSalePriceCurrencyId ?? 1
                                                    )}
                                                </h2>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <div className={style.hr}></div>
                                    <Col>
                                        <Row gutter={[16, 16]}>
                                            <Col md={8} xs={8} className={style.period}>
                                                <InfoBox
                                                    head={t("machine-detail.machine-id")}
                                                    text={machineAllDetail?.id ? machineAllDetail?.id.toString() : "-"}
                                                />
                                            </Col>
                                            <Col md={8} xs={8} className={style.period}>
                                                <InfoBox
                                                    head={t("machine-detail.model")}
                                                    text={machineAllDetail?.modelName ? machineAllDetail?.modelName : "-"}
                                                />
                                            </Col>
                                            {machineAllDetail?.operatingWeight && (
                                                <Col md={8} xs={8} className={style.period}>
                                                    <InfoBox
                                                        head={t("machine-detail.working-weight")}
                                                        text={machineAllDetail?.operatingWeight.toString() + "(ton)"}
                                                    />
                                                </Col>
                                            )}
                                            <Col md={8} xs={8} className={style.period}>
                                                <InfoBox
                                                    head={t("machine-detail.brand")}
                                                    text={
                                                        machineAllDetail?.brandOther
                                                            ? machineAllDetail?.brandOther ?? ""
                                                            : machineAllDetail?.brand?.name ?? ""
                                                    }
                                                />
                                            </Col>
                                            <Col md={8} xs={8} className={style.period}>
                                                <InfoBox
                                                    head={t("machine-production-year")}
                                                    text={
                                                        machineAllDetail?.productionYear ? machineAllDetail?.productionYear.toString() : "-"
                                                    }
                                                />
                                            </Col>

                                            <Col md={8} xs={8} className={style.period}>
                                                <InfoBox
                                                    head={t("reservation.update-date")}
                                                    text={machineAllDetail?.modifiedDate ? moment(machineAllDetail?.modifiedDate) : "-"}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    {/* <Col>
                                        <Row gutter={[8, 8]} align="middle">
                                            <Col style={{ display: "flex", margin: "1rem 0" }}>
                                                <Info />
                                            </Col>
                                            <Col className={style.kdv}>{t("reservation.tax-info")}</Col>
                                        </Row>
                                    </Col> */}
                                    {role === "lessor" ? (
                                        <>
                                            {!machineAllDetail?.isMachineRemovedFromSale && (
                                                <Col>
                                                    <PrimaryButton
                                                        onClick={() => navigate(`/sale-machines/edit?macid=` + machineAllDetail?.id)}
                                                        style={{ width: "100%", justifySelf: "end" }}
                                                    >
                                                        {t("common.edit")}
                                                    </PrimaryButton>
                                                </Col>
                                            )}
                                            {machineAllDetail?.rentalTypes && machineAllDetail?.rentalTypes.length > 0 ? (
                                                <>
                                                    <Col>
                                                        <PrimaryButton
                                                            className={style.showRentalButton}
                                                            onClick={() => {
                                                                setLessorPurpose(LessorPurpose.OnlyForRent);
                                                                navigate(`/machines/view?macid=` + machineAllDetail?.id);
                                                            }}
                                                            style={{
                                                                width: "100%",
                                                                background: "#7F5808",
                                                                color: "#FFFFFF",
                                                                borderColor: "#7F5808",
                                                            }}
                                                        >
                                                            {t("reservation-show-rental-text")}
                                                        </PrimaryButton>
                                                    </Col>
                                                    {!machineAllDetail?.isMachineRemovedFromSale && (
                                                        <Col>
                                                            <PrimaryButton
                                                                className={style.showRentalButton}
                                                                onClick={() => setShowRemoveFromShowcaseModal(true)}
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#7F5808",
                                                                    color: "#FFFFFF",
                                                                    borderColor: "#7F5808",
                                                                }}
                                                            >
                                                                {t("machine-detail.remove-machine-from-release")}
                                                            </PrimaryButton>
                                                        </Col>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <Col>
                                                        <PrimaryButton
                                                            className={style.showRentalButton}
                                                            onClick={() => {
                                                                setLessorPurpose(LessorPurpose.OnlyForRent);
                                                                navigate("/machines/edit?macid=" + machineAllDetail?.id + "&s=t");
                                                            }}
                                                            style={{
                                                                width: "100%",
                                                                background: "#7F5808",
                                                                color: "#FFFFFF",
                                                                borderColor: "#7F5808",
                                                            }}
                                                        >
                                                            {t("machine-detail.create-rental-machine")}
                                                        </PrimaryButton>
                                                    </Col>
                                                    {!machineAllDetail?.isMachineRemovedFromSale && (
                                                        <Col>
                                                            <PrimaryButton
                                                                className={style.showRentalButton}
                                                                onClick={() => setShowRemoveFromShowcaseModal(true)}
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#7F5808",
                                                                    color: "#FFFFFF",
                                                                    borderColor: "#7F5808",
                                                                }}
                                                            >
                                                                {t("machine-detail.remove-machine-from-release")}
                                                            </PrimaryButton>
                                                        </Col>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Col>
                                                <PrimaryButton onClick={doGetLessorInfo} loading={atomLoading} style={{ width: "100%" }}>
                                                    {t("sale-machines.show-owner")}
                                                </PrimaryButton>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            </BoxContainer>
                        </Col>
                    </Row>
                </Col>
                <BoxContainer>
                    <Col className={style.machineHeader}>{t("machine-detail.specs")}</Col>
                    <Col>
                        <Row gutter={[16, 16]}>
                            <Col md={4} xs={8} className={style.period}>
                                <InfoBox head={t("machine-detail.machine-id")} text={machineAllDetail?.id?.toString() || "-"} />
                            </Col>
                            <Col md={4} xs={8} className={style.period}>
                                <InfoBox head={t("machines.category")} text={machineAllDetail?.subCategory?.category?.name || "-"} />
                            </Col>
                            <Col md={4} xs={8} className={style.period}>
                                <InfoBox head={t("machines.subcategory")} text={machineAllDetail?.subCategory?.name || "-"} />
                            </Col>
                            {machineAllDetail?.brandOther ? (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machine-detail.brand")} text={machineAllDetail?.brandOther || "-"} />
                                </Col>
                            ) : (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machine-detail.brand")} text={machineAllDetail?.brand?.name || "-"} />
                                </Col>
                            )}
                            <Col md={4} xs={8} className={style.period}>
                                <InfoBox head={t("machine-detail.model")} text={machineAllDetail?.modelName || "-"} />
                            </Col>
                            <Col md={4} xs={8} className={style.period}>
                                <InfoBox head={t("machine-production-year")} text={machineAllDetail?.productionYear?.toString() || "-"} />
                            </Col>
                            {machineAllDetail?.bladeWidth && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.bladeWidth")}
                                        text={
                                            machineAllDetail?.bladeWidth.toString() + " " + t("machines.additional-fields.bladeWidthSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.bomLength && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.bomLength")}
                                        text={machineAllDetail?.bomLength.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.breakingInfo && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.breakingInfo")}
                                        text={machineAllDetail?.breakingInfo.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.cameraResolution?.name && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.cameraResolution")}
                                        text={machineAllDetail?.cameraResolution?.name?.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.carryingCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.carryingCapacity")}
                                        text={
                                            machineAllDetail?.carryingCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.carryingCapacitySuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.cleaningCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.cleaningCapacity")}
                                        text={
                                            machineAllDetail?.cleaningCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.cleaningSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.liftingHeight && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.liftingHeight")}
                                        text={
                                            machineAllDetail?.liftingHeight.toString() +
                                            " " +
                                            t("machines.additional-fields.liftingHeightSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.compressionWidth && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.compressionWidth")}
                                        text={machineAllDetail?.compressionWidth.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.concretePumpingCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.concretePumpingCapacity")}
                                        text={
                                            machineAllDetail?.concretePumpingCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.concreteSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.drivingEquipment && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.drivingEquipment")}
                                        text={machineAllDetail?.drivingEquipment}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.elevatorType?.name && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.elevatorTypeId")}
                                        text={machineAllDetail?.elevatorType.name}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.engineBrand && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machines.additional-fields.engineBrand")} text={machineAllDetail?.engineBrand} />
                                </Col>
                            )}
                            {machineAllDetail?.enginePower && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.enginePower")}
                                        text={machineAllDetail?.enginePower.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.flightDistance && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.flightDistance")}
                                        text={
                                            machineAllDetail?.flightDistance.toString() +
                                            " " +
                                            t("machines.additional-fields.flightDistanceSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.flightTime && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.flightTime")}
                                        text={machineAllDetail?.flightTime.toString() + " " + t("machines.additional-fields.flightSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.floorCount && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.floorCount")}
                                        text={machineAllDetail?.floorCount.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.freeAirOutput && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.freeAirOutput")}
                                        text={
                                            machineAllDetail?.freeAirOutput.toString() + " " + t("machines.additional-fields.freeAirSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.frequency && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.frequency")}
                                        text={
                                            machineAllDetail?.frequency.toString() + " " + t("machines.additional-fields.frequencySuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.fuelType?.name && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machines.additional-fields.fuelTypeId")} text={machineAllDetail?.fuelType.name} />
                                </Col>
                            )}
                            {machineAllDetail?.garbageCollectionBin && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.garbageCollectionBin")}
                                        text={machineAllDetail?.garbageCollectionBin.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.hasCabin && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machines.additional-fields.hasCabin")} text="Mevcut" />
                                </Col>
                            )}
                            {machineAllDetail?.hasPlatform && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machines.additional-fields.hasPlatform")} text="Mevcut" />
                                </Col>
                            )}
                            {machineAllDetail?.hasTowerElevationSystem && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machines.additional-fields.hasTowerElevationSystem")} text="Mevcut" />
                                </Col>
                            )}
                            {machineAllDetail?.height && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.height")}
                                        text={machineAllDetail?.height.toString() + " " + t("machines.additional-fields.heightSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.lightningCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.lightningCapacity")}
                                        text={
                                            machineAllDetail?.lightningCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.lightningCapacitySuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.loadCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.loadCapacity")}
                                        text={machineAllDetail?.loadCapacity.toString() + " " + t("machines.additional-fields.loadSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.maxDepth && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox head={t("machines.additional-fields.maxDepth")} text={machineAllDetail?.maxDepth.toString()} />
                                </Col>
                            )}
                            {machineAllDetail?.maxFeedingSize && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.maxFeedingSize")}
                                        text={
                                            machineAllDetail?.maxFeedingSize.toString() +
                                            " " +
                                            t("machines.additional-fields.maxFeedingSizeSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.maxHeight && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.maxHeight")}
                                        text={machineAllDetail?.maxHeight.toString() + " " + t("machines.additional-fields.heightSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.maxLoadCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.maxLoadCapacity")}
                                        text={
                                            machineAllDetail?.maxLoadCapacity.toString() +
                                            " " +
                                            ([10].includes(machineAllDetail.subCategory?.category?.id ?? -1)
                                                ? t("common.kg")
                                                : t("common.ton"))
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.maxPower && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.maxPower")}
                                        text={machineAllDetail?.maxPower.toString() + " " + t("machines.additional-fields.powerSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.maxPressure && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.maxPressure")}
                                        text={
                                            machineAllDetail?.maxPressure.toString() + " " + t("machines.additional-fields.pressureSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.maxWidth && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.maxWidth")}
                                        text={machineAllDetail?.maxWidth.toString() + " " + t("machines.additional-fields.widthSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.minPower && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.minPower")}
                                        text={machineAllDetail?.minPower.toString() + " " + t("machines.additional-fields.powerSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.nominalCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.nominalCapacity")}
                                        text={
                                            machineAllDetail?.nominalCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.capacitySuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.operatingCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.operatingCapacity")}
                                        text={
                                            machineAllDetail?.operatingCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.capacitySuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.operatingHeight && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.operatingHeight")}
                                        text={
                                            machineAllDetail?.operatingHeight.toString() +
                                            " " +
                                            t("machines.additional-fields.heightSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.operatingLength && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.operatingLength")}
                                        text={
                                            machineAllDetail?.operatingLength.toString() +
                                            " " +
                                            t("machines.additional-fields.operationLengthSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.operatingWeight && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.operatingWeight")}
                                        text={machineAllDetail?.operatingWeight.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.operationHour && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.operationHour")}
                                        text={machineAllDetail?.operationHour.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.operationMileage && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.operationMileage")}
                                        text={
                                            machineAllDetail?.operationMileage.toString() +
                                            " " +
                                            t("machines.additional-fields.mileageSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.operationWidth && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.operationWidth")}
                                        text={
                                            machineAllDetail?.operationWidth.toString() + " " + t("machines.additional-fields.widthSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.platformLength && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.platformLength")}
                                        text={
                                            machineAllDetail?.platformLength.toString() +
                                            " " +
                                            t("machines.additional-fields.operationLengthSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.platformWidth && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.platformWidth")}
                                        text={
                                            machineAllDetail?.platformWidth.toString() + " " + t("machines.additional-fields.widthSuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.spraySpeed && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.spraySpeed")}
                                        text={machineAllDetail?.spraySpeed.toString() + " " + t("machines.additional-fields.spraySuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.tankCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.tankCapacity")}
                                        text={
                                            machineAllDetail?.tankCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.tankCapacitySuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.tankVolume && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.tankVolume")}
                                        text={machineAllDetail?.tankVolume.toString() + " " + t("machines.additional-fields.volumeSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.usageFields && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.usageFields")}
                                        text={machineAllDetail?.usageFields.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.volume && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.volume")}
                                        text={machineAllDetail?.volume.toString() + " " + t("machines.additional-fields.volumeSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.waterTankCapacity && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.waterTankCapacity")}
                                        text={
                                            machineAllDetail?.waterTankCapacity.toString() +
                                            " " +
                                            t("machines.additional-fields.capacitySuffix")
                                        }
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.width && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.width")}
                                        text={machineAllDetail?.width.toString() + " " + t("machines.additional-fields.widthSuffix")}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.workingRadius && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.workingRadius")}
                                        text={machineAllDetail?.workingRadius.toString()}
                                    />
                                </Col>
                            )}
                            {machineAllDetail?.workingSpeed && (
                                <Col md={4} xs={8} className={style.period}>
                                    <InfoBox
                                        head={t("machines.additional-fields.workingSpeed")}
                                        text={
                                            machineAllDetail?.workingSpeed.toString() +
                                            " " +
                                            t("machines.additional-fields.workingSpeedSuffix")
                                        }
                                    />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </BoxContainer>

                <BoxContainer className={style.saleNoteContainer}>
                    <Col className={style.machineHeader}>{t("machine-detail.additional-info")}</Col>
                    <Col className={style.period}>{t("machine-detail.description-header")}</Col>
                    <Col>{machineAllDetail?.saleNotes || t("machine-detail.none-notes-text")}</Col>
                </BoxContainer>
                {role === "lessor" && 
                    <MachineSaleStatisticPart statistics={statistic}/>
                }
            </Row>
            <Modal
                title={<span style={{fontWeight:'bold'}}>{t("sale-machines.owner")}</span>}
                onCancel={() => {
                    setShowLessorCompanyModal(false);
                }}
                visible={showLessorCompanyModal}
                footer={null}
            >
                    {
                        lessorInfo?.authorisedLessors?.map((lessor,index) => {
                            return (
                                <div>
                                    <p>{t("sale-machines.owner-name")} {lessor?.name}</p>
                                    <p>{t("sale-machines.owner-phone")} 0{lessor?.phone}</p>
                                    {
                                        index < (lessorInfo.authorisedLessors?.length ?? 0) - 1 && 
                                        <Divider />
                                    }
                          
                                </div>
                            );
                        })
                    }
            </Modal>
            <MachineShowcaseRemovalModal
                show={showRemoveFromShowcaseModal}
                message={t("machine-detail.showcase-removal-modal.message")}
                onClickOk={async () => {
                    setShowRemoveFromShowcaseModal(false);
                    const res = await handleRemoveFromShowcase();
                    if (res) {
                        setShowRemoveShowcaseAccepted(true);
                    } else {
                        setShowRemoveShowcaseError(true);
                    }
                }}
                onCloseClick={() => setShowRemoveFromShowcaseModal(false)}
                modalType="pre-apply"
            />
            <MachineShowcaseRemovalModal
                show={showRemoveShowcaseAccepted || showRemoveShowcaseError}
                message={
                    showRemoveShowcaseAccepted
                        ? t("machine-detail.showcase-removal-modal.accepted")
                        : t("machine-detail.showcase-removal-modal.error")
                }
                onClickOk={async () => {
                    if (showRemoveFromShowcaseModal) {
                    } else {
                    }
                }}
                onCloseClick={() => {
                    setShowRemoveShowcaseAccepted(false);
                    setShowRemoveShowcaseError(false);
                }}
                modalType={showRemoveShowcaseAccepted ? "accepted" : "error"}
            />
        </SecureLayout>
    );
};

export default SaleMachineDetail;

import styles from "../styles/card-container.module.scss";
import { ArrowRightOutlined } from "@ant-design/icons";
import { MouseEventHandler } from "react";

export type CardButtonProps = {
    icon: any;
    label: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
};

const CardButton = (props: CardButtonProps) => {
    const cursorString = !!props.onClick ? "pointer" : "default";

    return (
        <div className={styles.cardContainer} onClick={props.onClick} style={{ cursor: cursorString }}>
            {props.icon}
            <span>{props.label}</span>
            <ArrowRightOutlined style={{ color: "#FEB111", fontSize: "2rem" }} />
        </div>
    );
};

export default CardButton;

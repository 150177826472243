import OnboardLayout from "../../components/layouts/onboard-layout";
import { useEffect, useLayoutEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx';

type AgreementProps = {
    type: 'PrivacyPolicy' | 'UserAgreement' | 'ConsentApprovalText'
}

const Agreement = (props: AgreementProps) => {

    const [agreementText, setAgreementText] = useState<string>('');

    useEffect(() => {
        if (props.type === 'PrivacyPolicy') {
            document.title = 'Aydınlatma Metni - MachineGo';
            let descMeta = document.querySelector("meta[name='description']");
            if (descMeta)  
                descMeta.setAttribute('content', 'MachineGo olarak kişisel verilerinizi önemsiyoruz. Gizlilik politikamızda, verilerinizin nasıl toplandığı, işlendiği ve korunduğu hakkında detaylı bilgi bulabilirsiniz.');
            let ogDescMeta = document.querySelector("meta[property='og:description']");
            if (ogDescMeta)  
                ogDescMeta.setAttribute('content', 'MachineGo olarak kişisel verilerinizi önemsiyoruz. Gizlilik politikamızda, verilerinizin nasıl toplandığı, işlendiği ve korunduğu hakkında detaylı bilgi bulabilirsiniz.');
            let ogTitle = document.querySelector("meta[property='og:title']");
            if (ogTitle)  
                ogTitle.setAttribute('content', 'Aydınlatma Metni - MachineGo');
        }
        return () => {
            document.title = 'MachineGo';
            let descMeta = document.querySelector("meta[name='description']");
            if (descMeta)  
                descMeta.setAttribute('content', 'Web site created using create-react-app');
            let ogDescMeta = document.querySelector("meta[property='og:description']");
            if (ogDescMeta)  
                ogDescMeta.setAttribute('content', 'İşinizin Makine Gücü MachineGo.com ile binlerce ilan arasından dilediğiniz makineyi satın alın veya kiralayın.');
            let ogTitle = document.querySelector("meta[property='og:title']");
            if (ogTitle)  
                ogTitle.setAttribute('content', 'MachineGo.com: İşinizin Makine Gücü');
        }
    }, [props.type]);
    useLayoutEffect(() => {
        switch (props.type) {
            case 'PrivacyPolicy': 
                import('../../markdown/agreements/tr/aydinlatma-metni.md').then(res => {
                    fetch(res.default).then((response) => response.text()).then((text) => {
                        setAgreementText(text)
                    })
                })
            break;
            case 'UserAgreement': 
            import('../../markdown/agreements/tr/kullanici-sozlesmesi.md').then(res => {
                fetch(res.default).then((response) => response.text()).then((text) => {
                    setAgreementText(text)
                })
            })
            break;
            case 'ConsentApprovalText': 
                import('../../markdown/agreements/tr/kurumsal-ve-bireysel-uye-acik-riza-metni.md').then(res => {
                    fetch(res.default).then((response) => response.text()).then((text) => {
                        setAgreementText(text)
                    })
                })
            break;
        }
    }, [props.type]);

    return (
        <OnboardLayout>
            <Markdown>{agreementText}</Markdown>
        </OnboardLayout>
    )
}

export default Agreement; 
import { Content, Footer, Header } from "antd/lib/layout/layout";
import { ReactElement } from "react";
import BaseLayout from "./base-layout";
import { ReactComponent as Logo } from "../assets/logo.svg";
import styles from "../styles/onboard-layout.module.scss";
import { t } from "../../translate";
import { activeRootSiteUrl } from "../../store/app-constants";

export type OnboardLayoutProps = {
    children: any;
    header?: string | ReactElement;
    smallLogo?: boolean;
    h2?: string | ReactElement;
    footerVisible?: boolean;
};

const OnboardLayout = (props: OnboardLayoutProps) => {
 
    const logo = props.smallLogo ? <Logo height={"2.5rem"} onClick={() => {
        window.location.replace(activeRootSiteUrl);
    }} /> : <Logo onClick={() => {
        window.location.replace(activeRootSiteUrl);
    }}/>;
    return (
        <BaseLayout>
            <Header className={styles.header}>
                {logo}
                {props.header && <h1>{props.header}</h1>}
                {props.h2 && <h2 className={styles.signupHeaderOne}>{props.h2}</h2>}
            </Header>
            <Content className={styles.content}>{props.children}</Content>
            {props.footerVisible && (
                <Footer className={styles.footer}>
                    <span style={{ textAlign: "center" }}>{t("common.copyright")}</span>
                </Footer>
            )}
        </BaseLayout>
    );
};

export default OnboardLayout;
